import { getProjectMarketing, updateProjectMarketing } from '@/services/projects'
import { errorModal, successModal } from '@/modalMessages'

const schema = [
  {
    type: 'select',
    name: 'table_form',
    placeholder: 'Selecteer tabelvorm',
    options: {
      1: 'Teaser',
      2: 'Volledig'
    }
  },
  {
    type: 'translatable',
    name: 'call_to_action',
    label: 'Call to action'
  },
  {
    type: 'translatable',
    name: 'strength_1',
    label: 'Troef 1'
  },
  {
    type: 'translatable',
    name: 'strength_2',
    label: 'Troef 2'
  },
  {
    type: 'translatable',
    name: 'strength_3',
    label: 'Troef 3'
  }
]

const actions = {
  async load (projectId) {
    try {
      const response = await getProjectMarketing(projectId)
      return response.data
    } catch (error) {
      errorModal('Fout bij het laden van marketinggegevens, probeer het opnieuw.')
      throw error
    }
  },
  async submit (projectId, data) {
    try {
      const response = await updateProjectMarketing(projectId, data)
      successModal('De wijzigingen zijn opgeslagen.')
      return response
    } catch (error) {
      errorModal('Er ging iets mis bij het bewerken.')
      throw error
    }
  }
}

export default {
  heading: 'Eigen website',
  schema,
  actions
}
